import {postData, getData, putData, deleteData} from 'helpers/apiHelper'
import { MAIN_PATH, GET_PACKAGE,ADD_PACKAGE, UPDATE_PACKAGE, VIEW_PACKAGE, DELETE_PACKAGE, SEND_PACKAGE, DELETE_PACKAGE_INVITATION, CANCEL_PACKAGE_INVITATION, RESEND_PACKAGE_INVITATION, GET_PACKAGE_INVITATIONS, GET_PACKAGE_UTILIZATION_HISTORY, GET_PACKAGE_INVITATION_CLIENTS, GET_UTILIZATION_CLIENT  } from './apiPath'
import { getPackage, addPackage, viewPackage, updatePackage, deletePackage, getPackageInvitations, getPackageUtilizationHistory} from 'store/actions/package';
import { cancelClientPackageInvitation, removeClientPackageInvitation } from "store/actions/client";

//get
export function getPackage_(value, callback) {
  let url = `${MAIN_PATH}${GET_PACKAGE}`;
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(getPackage(data, callback));
      return data;
    });
}

//add
export function addPackage_(value, callback) {
  let url = `${MAIN_PATH}${ADD_PACKAGE}`;
  // let url = `http://localhost:3000/STAGING/packages/add`;
  return (dispatch) => postData(url, value)
    .then((data) => {
      dispatch(addPackage(data, callback));
      return data;
    });
}

//view
export function viewPackage_(value, callback) {
  return getData(`${MAIN_PATH}${VIEW_PACKAGE}${value}`)
}

//edit
export function updatePackage_(id, value, callback) {
  let url = `${MAIN_PATH}${UPDATE_PACKAGE}${id}`;
  // let url = `http://localhost:3000/STAGING/packages/edit/${id}`;
  return (dispatch) => putData(url, value)
    .then((data) => {
      dispatch(updatePackage(data, callback));
      return data;
    });
}

//delete
export function deletePackage_(id) {
  return (dispatch) => deleteData(`${MAIN_PATH}${DELETE_PACKAGE}${id}`)
    .then((data) => {
       dispatch(deletePackage(data));
      return data;
    });
}

// send package to client
export function sendPackage(data) {
  let { packageId, values } = data;
  let url = `${MAIN_PATH}${SEND_PACKAGE}${packageId}`;
  // let url = `http://localhost:3000/dev/clients/packages/invitation/send/${packageId}`
  return postData(url, values);
}

// get package invitations
export function getInvitationsList(data, callback) {
  let { packageId, status } = data;
  let url = `${MAIN_PATH}${GET_PACKAGE_INVITATIONS}/${packageId}?status=${status}`;
  // let url = `http://localhost:3000/STAGING/packages/invitation/list/${packageId}?status=${status}`;
  return (dispatch) => getData(url)
    .then((data) => {
      dispatch(getPackageInvitations({ status, data}, callback));
      return data;
    });
}

// resend package invitation
export function resendPackageInvitation(data, callback) {
  let { invitationId, values } = data;
  let url = `${MAIN_PATH}${RESEND_PACKAGE_INVITATION}${invitationId}`;
  // let url = `http://localhost:3000/dev/clients/packages/invitation/resend/${invitationId}`;
  return postData(url,values);
}

// cancel package invitation
export function cancelPackageInvitation(data, callback) {
  let { invitationId, values } = data;
  return (dispatch) =>
    postData(
      `${MAIN_PATH}${CANCEL_PACKAGE_INVITATION}${invitationId}`,
      values
    ).then((data) => {
      dispatch(cancelClientPackageInvitation(data, callback));
      return data;
    });
}

// remove package invitation
export function removePackageInvitation(data, callback) {
  let { invitationId, values } = data;
  return (dispatch) =>
    deleteData(
      `${MAIN_PATH}${DELETE_PACKAGE_INVITATION}${invitationId}`,
      values
    ).then(() => {
      dispatch(removeClientPackageInvitation(data, callback));
      return data;
    });
}

// package utilization history
export function getPackageUtilization(data, callback) {
  let { packageId, params } = data;
  let url = `${MAIN_PATH}${GET_PACKAGE_UTILIZATION_HISTORY}/${packageId}`;
  // let url = `http://localhost:3000/STAGING/packages/activity/list/${packageId}`;
  return (dispatch) => postData(url, params)
    .then((data) => {
      dispatch(getPackageUtilizationHistory(data, callback));
      return data;
    });
}

// get package invitation clients
export function getInvitationClients(packageId, callback) {
  let url = `${MAIN_PATH}${GET_PACKAGE_INVITATION_CLIENTS}/${packageId}`;
  // let url = `http://localhost:3000/STAGING/packages/invitation/clients/${packageId}`;
  return getData(url);
}

// get utilization history by client
export function getUtilizationHistoryByClient(payload, callback) {
  let url = `${MAIN_PATH}${GET_UTILIZATION_CLIENT}`;
  // let url = `http://localhost:3000/STAGING/packages/activity/client`;
  return postData(url, payload);
}
import { useEffect } from "react";
import { connect } from "react-redux";
import { PACKAGE_INVITE, TODO } from "common/viewContent";
import moment from "moment";
import { Col, Dropdown } from "react-bootstrap";
import parse from "html-react-parser";
import NewInvitation from "../NewInvitation";
import { getInvitationsList } from "store/services/packageService";
import * as actions from "store/actions";
import { useParams } from "react-router-dom";
import { FULL_NAME } from "helpers/common";

const PackagePurchaseHistoryTab = ({ dispatch, invitations }) => {
  const { id: packageId } = useParams();

  useEffect(() => {
    getPurchaseHistory();
  }, []);

  async function getPurchaseHistory() {
    try {
      let payload = {
        packageId,
        status: "complete"
      }
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getInvitationsList(payload));
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.error("Error fetching package invitations", err);
    }
  }

  return (
    <>
      <NewInvitation />
      <div className="commonCardBox">
        <div className="table-responsive">
          <table className="table">
            <thead className="bg-transparent">
              <tr>
                <th className="p-3 fw-normal text-dark fw-bold text-uppercase">
                  Client Name
                </th>
                <th className="p-3 fw-normal text-dark fw-bold text-uppercase">
                  Session Remaining
                </th>
                <th className="p-3 fw-normal text-dark fw-bold text-uppercase">
                  Purchased Date
                </th>
              </tr>
            </thead>
            <tbody>
              {invitations?.length > 0 ? invitations.map((invitation, index) => {
                return (
                  <tr>
                    <td className="px-3 py-2 border-0">
                      <p className="theme-clr m-0 ws-normal fw-bold">
                        {FULL_NAME(invitation?.first_name, invitation?.last_name)}
                      </p>
                    </td>
                    <td className="px-3 py-2 border-0">
                      <p className="theme-clr m-0">
                      {invitation?.appointments_remaining}/{invitation?.appointments_count}
                      </p>
                    </td>
                    <td className="px-3 py-2 border-0">
                      <p className="theme-clr m-0">
                        {moment(invitation?.created_at).format('MMM D, YYYY hh:mm A')}
                      </p>
                    </td>
                  </tr>
                )
              }) : (
                <tr>
                  <td className="text-center" colSpan={4}>No data found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

const mapStateToPros = (state) => {
  return {
    invitations: state.Package.packageInvitations?.complete,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(PackagePurchaseHistoryTab);

import { GET_PACKAGE, ADD_PACKAGE, VIEW_PACKAGE, UPDATE_PACKAGE, DELETE_PACKAGE, PACKAGE_INIVITATIONS, GET_PACKAGE_UTILIZATION } from 'store/actions/package';

const initialState = {
    isAuthenticated: false,
    packages: [],
    package: {},
    packageInvitations: {
      invited: [],
      complete: [],
    },
    utilizationHistory: [],
    loader:false,
    totalItemsCount:0,
    itemsCountPerPage:0,
};

//get 
const getPackage = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
        ...state,
        isAuthenticated: true,
        packages:data.body,
    };
  return stateObj;
};

//add 
const addPackage = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      packages:data.body,
    };
    return stateObj;
};

  //view 
const viewPackage = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      package:data.body,
    };
    return stateObj;
};

  //update 
const updatePackage = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      package:data.body,
    };
    return stateObj;
};

//delete 
const deletePackage = (state, payload) => {
  const { data } = payload;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    package:[],
  };
  return stateObj;
};

const getPackageInvitations = (state, payload) => {
  const { callback } = payload;
  const { data, status } = payload?.data || {};
  
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      packageInvitations: {
        ...state.packageInvitations,
        [status]: data.body,
      }
    };
    return stateObj;
}

const getPackageUtilization = (state, payload) => {
  const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      utilizationHistory: data.body,
    };
    return stateObj;
}

const FAQ = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GET_PACKAGE:
          return getPackage(state, payload);
        case ADD_PACKAGE:
            return addPackage(state, payload);
        case VIEW_PACKAGE:
          return viewPackage(state, payload);
        case UPDATE_PACKAGE:
          return updatePackage(state, payload);
        case DELETE_PACKAGE:
          return deletePackage(state, payload);
        case PACKAGE_INIVITATIONS:
          return getPackageInvitations(state, payload);
        case GET_PACKAGE_UTILIZATION:
          return getPackageUtilization(state, payload);
        default:
        return state;
    };
}
export default FAQ;
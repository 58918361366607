import { useState } from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ReactComponent as PlusIcn } from "Assets/Images/icn/plus.svg";
import SendPackageInvitePop from "../../Component/SendPackageInvitePop";
import { getClientList } from "store/services/clientService";
import * as actions from "store/actions";
import { connect } from "react-redux";
import { getInvitationClients, getInvitationsList, sendPackage } from "store/services/packageService";

const NewInvitation = ({ dispatch, packageDetails }) => {
  const { id: packageId } = useParams();
  const [sending, setSending] = useState(false);
  const [packageInvitePop, setPackageInvitePop] = useState(false);
  const [sentSuccessPop, setSentSuccessPop] = useState(false);
  const [packageLinkPopup, setPackageLinkPopup] = useState(false);
  const [currentPacakge, setCurrentPackage] = useState(null);
  const [generatedPackageLink, setGeneratedPackageLink] = useState(null);
  const [packageSentSuccessMsg, setPackageSentSuccessMsg] = useState(null);

  const [clients, setClients] = useState(null);

  const togglePackageInvitePopup = () => {
    setPackageInvitePop(!packageInvitePop);
  };

  const openSendPackagePopup = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));

      let response = await getInvitationClients(packageId);
      if(response?.success === true && response?.body?.length) {
        setClients(response.body);
      }

      dispatch(actions.persist_store({ loader: false }));
      setPackageInvitePop(true);
    } catch(err) {
      console.log("Error fetching client list", err);
    }
  }

  const sendPackageToClient = async (client, dontSendEmail, setSending) => {
    try {
      let data = {
        packageId,
        values: {
          client_id: client?.id,
          do_not_package_email_invite: dontSendEmail
        }
      }
      setSending(true);
      dispatch(actions.persist_store({ loader: true }));
      let res = await sendPackage(data);
      await dispatch(getInvitationsList({
        packageId,
        status: "invited"
      }));
      dispatch(actions.persist_store({ loader: false }));
      setSending(false);
      setPackageInvitePop(false);
      if(dontSendEmail && (res.success === true && res.body.url)) {
        setGeneratedPackageLink(res.body.url)
        setPackageLinkPopup({
          open: true,
          data: {
            client
          }
        });
      } else if(res.success === true) {
        setSentSuccessPop(true);
        setPackageSentSuccessMsg(res.message);
      }
    } catch(err) {
      console.log("Error sending package to client: ", err);
      setSending(false);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const toggleAddPackagePop = (val) => {
    setAddPackagePop(!addPackagePop);
  };

  return (
    <>
      <SendPackageInvitePop
        packageInvitePop={packageInvitePop}
        togglePackageInvitePopup={togglePackageInvitePopup}
        handleClickSendPackage={sendPackageToClient}
        clients={clients}
      />
      <div className="top d-flex align-items-start justify-content-between gap-10 mb-3">
        <div></div>
        <div className="right">
          <Button
            onClick={openSendPackagePopup}
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
          >
            <span className="icn me-2">
              <PlusIcn />
            </span>
            Add a new Invitation
          </Button>
        </div>
      </div>
    </>
  )
}

const mapStateToPros = (state) => {
  return {
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(NewInvitation);

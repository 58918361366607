import React, { useState, useCallback, useEffect } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import { generatePath, Link } from "react-router-dom";
import { connect } from "react-redux";
import AddPackagePop from "./Component/AddPackagePop";
// imported
import {
  getPackage_,
  addPackage_,
  updatePackage_,
  deletePackage_,
  sendPackage,
} from "store/services/packageService";
import SendPackageInvitePop from "./Component/SendPackageInvitePop";
import PackageSentSuccessPop from "./Component/PackageSentSuccessPop";
import PackageLinkPopup from "./Component/PackageLinkPopup";

import * as actions from "store/actions";
import { getClientList } from "store/services/clientService";
import { DELETE_ALERT, SUCCESS_MESSAGE } from "common/constantMessage";
import { packageDetails } from "Routes/paths";
import { formatPrice, getPackageSessionPrice } from "helpers/common";

const MyPackage = ({ dispatch, PackageList, clients, stripeConnection }) => {
  const [addPackagePop, setAddPackagePop] = useState(false);
  const [sending, setSending] = useState(false);
  const [packageInvitePop, setPackageInvitePop] = useState(false);
  const [sentSuccessPop, setSentSuccessPop] = useState(false);
  const [packageLinkPopup, setPackageLinkPopup] = useState(false);
  const [currentPacakge, setCurrentPackage] = useState(null);
  const [generatedPackageLink, setGeneratedPackageLink] = useState(null);
  const [packageSentSuccessMsg, setPackageSentSuccessMsg] = useState(null);

  useEffect(() => {
    getList_();
  }, []);

  // get packages list
  async function getList_() {
    try {
      await dispatch(getPackage_());
    } catch (err) {
      console.log(err);
    }
  }

  // add package
  const submitData_ = async (values, {setSubmitting, resetForm}) => {
    try {
      setSubmitting(true);
      dispatch(actions.persist_store({ loader:true}));
      let response = null;
      if(currentPacakge){
        response = await dispatch(updatePackage_(currentPacakge.id, values));
      } else {
        response = await dispatch(addPackage_(values));
      }

      if(response && response.success === true && response.message) {
        SUCCESS_MESSAGE(response.message);
      }
      
      setAddPackagePop(false);
      resetForm();
      await getList_();
      setSubmitting(false)
      dispatch(actions.persist_store({ loader: false}));
      // setSateCurrentValue(null)
    } catch (err) {
      console.log(err, "==============================");
      setSubmitting(false);
      dispatch(actions.persist_store({ loader: false}));
    }
  };

  // delete package
  const deletePackage = async (packageId) => {
    try {
      await DELETE_ALERT().then(async (willDelete) => {
        if (willDelete) {
          dispatch(actions.persist_store({ loader: true }));
          await dispatch(deletePackage_(packageId));
          await getList_();
          dispatch(actions.persist_store({ loader: false }));
        }
      });
    } catch (err) {
      console.log("Error deleting package", err);
      dispatch(actions.persist_store({ loader: false }));
    }
  };

  const togglePackageInvitePopup = () => {
    setPackageInvitePop(!packageInvitePop);
  };

  const openSendPackagePopup = async (packageData) => {
    try {
      setCurrentPackage(packageData);
      let filter = {
        page: 0,
        limit: 999,
        type: {
          active: false
        },
        group: {
          current: false
        }
      }
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getClientList(filter));
      dispatch(actions.persist_store({ loader: false }));
      setPackageInvitePop(true);
    } catch(err) {
      console.log("Error fetching client list", err);
    }
  }

  const sendPackageToClient = async (client, dontSendEmail, setSending) => {
    try {
      let data = {
        packageId: currentPacakge.id,
        values: {
          client_id: client?.id,
          do_not_package_email_invite: dontSendEmail
        }
      }
      setSending(true);
      dispatch(actions.persist_store({ loader: true }));
      let res = await sendPackage(data);
      dispatch(actions.persist_store({ loader: false }));
      setSending(false);
      setPackageInvitePop(false);
      if(dontSendEmail && (res.success === true && res.body.url)) {
        setGeneratedPackageLink(res.body.url)
        setPackageLinkPopup({
          open: true,
          data: {
            client
          }
        });
      } else if(res.success === true) {
        setSentSuccessPop(true);
        setPackageSentSuccessMsg(res.message);
      }
    } catch(err) {
      console.log("Error sending package to client: ", err);
      setSending(false);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const toggleAddPackagePop = (val) => {
    setAddPackagePop(!addPackagePop);
    setCurrentPackage(null);
  };

  const editUpdatePackage = (data) => {
    try{
      setAddPackagePop(true);
      setCurrentPackage(data);
    }catch(err){
      console.log(err)
    }
  }

  return (
    <>
      <AddPackagePop
        addPackagePop={addPackagePop}
        toggleAddPackagePop={toggleAddPackagePop}
        submitData_={submitData_}
        currentPacakge={currentPacakge}
      />
      <SendPackageInvitePop
        packageInvitePop={packageInvitePop}
        togglePackageInvitePopup={togglePackageInvitePopup}
        handleClickSendPackage={sendPackageToClient}
        clients={clients}
      />
      <PackageLinkPopup
        showPopup={packageLinkPopup?.open || false}
        data={packageLinkPopup?.data || null}
        packageLink={generatedPackageLink}
        closePopup={() => {
          setPackageLinkPopup(false);
        }}
      />
      <PackageSentSuccessPop
        showPopup={sentSuccessPop}
        closePopup={() => {
          setSentSuccessPop(false);
        }}
        message={packageSentSuccessMsg}
      />
      <div className="top py-2 d-flex align-items-center justify-content-between">
        <div className="left">
          <h3 className="m-0 py-1 fw-bold">My Packages</h3>
        </div>
        <div className="right">
          <Button
            onClick={toggleAddPackagePop}
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
          >
            + Add Package
          </Button>
        </div>
      </div>
      <div className="TabBody py-3">
        <div className="info">
          <div className="py-2">
            {stripeConnection?.connection_response?.status != true &&
              <>
                <p className="m-0 text-dark py-1">
                  👋 To get started with packages, you'll first need to link your
                  Stripe Account so you can accept payments. Check out the Stripe
                  Integration Guide to see how. After you link your Stripe account
                  come back here you'll be able to setup your first package 🎉
                </p>
                <p className="m-0 text-dark py-1">
                  <Link className=" m-0 underline">View the Package Guide</Link> for
                  step by step instructions.
                </p>
              </>
            }
            { PackageList && !PackageList.length > 0 &&
              <p className="m-0 text-dark py-1">
                <p className="m-0">Your Stripe account is connected and ready to go ✅</p>
                <p className="m-0">
                  Let's create your first package! Check out the{` `}
                  <Link to="">Package Guide</Link>
                  {` `}to see step-by-step instructions.
                </p>
              </p>
            }
          </div>
        </div>
      </div>
      {PackageList.length > 0 &&
        PackageList.map((pack, i) => {
          return (
            <div className="TabBody py-2" key={i}>
              <Link
                to={generatePath(packageDetails, {id: pack.id})}
              >
                <div className="PackgeList">
                  <div className="py-2">
                    <div className="commonCardBox">
                      <div className="top px-lg-4 p-3 border-bottom commonContent d-flex align-items-center justify-content-between">
                        <div className="left">
                          <h3 className="m-0 fw-bold">{pack.package_name}</h3>
                        </div>
                        <div className="right">
                          <h6 className="m-0 fw-sbold ws-nowrap theme-clr">
                            Price{" "}
                            <h4 className="m-0 fw-bold d-inline-block theme-clr">
                              ${formatPrice(pack.package_price)} (USD)
                            </h4>
                          </h6>
                          <h6 className="m-0 fw-bold d-inline-block theme-clr">
                            ${getPackageSessionPrice(pack?.package_price, pack?.session_count)} (USD)/session
                          </h6>
                        </div>
                      </div>
                      <div className="CardBody py-2 px-lg-4 px-3">
                        <div className="commonContent">
                          <h4 className="m-0 py-2 fw-sbold">Description</h4>
                          <p className="m-0 py-1 is-multiline">{pack.package_desc}</p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="cardFooter d-flex align-items-center justify-content-between gap-10 px-lg-4 py-3 px-3 border-top">
                      <div className="left">
                        <Button
                          onClick={() => openSendPackagePopup(pack)}
                          className="d-flex align-items-center justify-content-center commonBtn btnSm"
                        >
                          Send To Client
                        </Button>
                      </div>
                      <div className="right d-flex gap-10">
                        <Button
                          onClick={() =>  deletePackage(pack.id)}
                          className="d-flex align-items-center justify-content-center btn-danger btnSm"
                        >
                          Delete
                        </Button>
                        <Button
                          onClick={() =>  editUpdatePackage(pack)}
                          className="d-flex align-items-center justify-content-center commonBtn btnSm"
                        >
                          Edit / Update
                        </Button>
                      </div>
                    </div> */}
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
    </>
  );
};

const mapStateToPros = (state) => {
  let stripeConnection = state.AccountSetting.integrations.find(integration => integration.connection_type == "stripe");
  return {
    PackageList: state.Package.packages,
    clients: state.Client.clients,
    stripeConnection
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(MyPackage);

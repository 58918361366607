// default route / 404
export const defaultRoute = "/*";

//AUTH
export const login = "/login";
export const signup = "/signup";
export const forgotPassword = "/forgot-password";
export const verifyOTP = "/verify-otp";
export const resetPassword = "/reset-password/:token";

//dashboard
export const dashboard = "/dashboard";

// my account
export const myAccount = "/my-account";

// Forms listing
export const myForms = "/my-forms";
export const myFormsPreview = "/my-forms/:formId/preview";

// onboarding
export const dashboardTermsCondition = "/dashboard/terms-and-conditions";

// subscription
export const dashboardSubscription = "/dashboard/subscription";

// subscription payment
export const dashboardSubscriptionPayment = "/dashboard/subscription-payment";

// subscription
export const subscriptionSuccess = "/subscription/payment-success";

// privacy policy
export const privacyPolicy = "/privacy-policy";

// terms & conditions
export const termsConditions = "/terms-conditions";

// add client
export const addClient = "/add-client";

// my clients
export const myClients = "/my-clients";

// my client details
export const myClientDetails = "/my-clients/:clientId/detail";

// food research
export const foodResearch = "/food-research";

// appointment
export const appointment = "/appointment";

// my docs
export const myDocs = "/my-docs";

// Add new form
export const addNewForm = "/my-forms/add-new";

// edit form
export const editForm = "/my-forms/:formId/edit";

// resources
export const resources = "/resources";

// help

export const helpHome = "https://nutritionaltherapyassociation.zendesk.com/hc/en-us";

// create Account
export const helpCreateAccount = "/help/create-account";

// Add First Client
export const helpAddFirstClient = "/help/add-first-client";

// Add Direct Link Client
export const helpDirectLinkClient = "/help/direct-link-client";

// Appointment Scheduler
export const helpAppointmentScheduler = "/help/scheduler/appointment-scheduler";

// Appointment page
export const helpAppointmentPage = "/help/scheduler/appointment-page";

// Schedule setup page
export const HelpScheduleSetup = "/help/scheduler/schedule-set-up";

// book appointment
export const HelpBookAppointment = "/help/scheduler/book-appointment";

// accept payment
export const HelpAcceptPayment = "https://nutritionaltherapyassociation.zendesk.com/hc/en-us/articles/31929854691603-Accepting-Payments";

// Paying appointemtn
export const HelpPayinlglAppointment = "/help/scheduler/paying-appointment";

// Paying appointemtn
export const HelpGoogleLinkAccount = "https://nutritionaltherapyassociation.zendesk.com/hc/en-us/sections/31927244370067-Google-Calendar-Integration";

//appointemtn syncing
export const HelpGoogleAppointmentSycing = "/help/google/appointment-syncing";

//Linking Account
export const HelpFullScriptLinkAccount = "https://nutritionaltherapyassociation.zendesk.com/hc/en-us/sections/31927262943507-Fullscript-Integration";

//Linking clients
export const HelpFullScriptLinkClients = "/help/fullscript/link-clients";

//Create Recommendation
export const HelpFullScriptCreateRecommendation = "/help/fullscript/create-recommendation";

//Create Recommendation
export const HelpFullScriptNaqRecommendation = "/help/fullscript/naq-recommendation";

//Client orders
export const HelpFullScriptClientOrders = "/help/fullscript/client-orders";

//Stripe link account
export const HelpStripeLinkAccount = "https://nutritionaltherapyassociation.zendesk.com/hc/en-us/sections/31927288882963-Stripe-Integration";

//Stripe Payment Method
export const HelpStripePaymentMethod = "/help/stripe/payment-method";

//Stripe Incomplete Payment
export const HelpStripeIncompletePayment = "/help/stripe/incomplete-payment";

//Practice Link Account
export const HelpPracticeLinkAccount = "https://nutritionaltherapyassociation.zendesk.com/hc/en-us/sections/31927540658067-Practice-Better-Integration";

//Practice Sending Viewing Naq
export const HelpPracticeSendingViewingNaq = "/help/practice/sending-viewing-naq";

//My Doc
export const HelpMyDoc = "/help/my-doc";

//My Form
export const HelpMyForm = "/help/my-form";

// Packages
export const HelpPackagesCreate = "https://nutritionaltherapyassociation.zendesk.com/hc/en-us/articles/31930386714643-Creating-a-Package";

// Packages sending purchasing
export const HelpPackagesSendingPurchasing = "/help/packages/sending-purchasing";

// Packages status
export const HelpPackagesStatus = "/help/packages/status";

// NAQ Introduction
export const HelpNaqIntroduction = "/help/naq-introduction";

// Food Mood journal
export const HelpFoodFMJournal = "/help/Food/fm-journal";

// Food Feeling log
export const HelpFoodFeelingLog = "/help/Food/feeling-log";

// Food Logging Comparison
export const HelpFoodLoggingComparison = "/help/Food/logging-comparison";

// Client Self Registeration
export const HelpClientSelfRegisteration = "/help/client-self-registeration";

// FAQ
export const HelpFAQ = "/help/faq";

// Public terms & conditions page
export const PublicTermsConditions = "/terms";

// Public privacy policy page
export const PublicPrivacyPolicy = "/terms";

// Mobile view reports
export const mobileTodoReports = "/mobile/todo-reports/:clientId";

// My Packages
export const packageDetails = "/my-packages/:id/detail";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Container, Nav } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
// icn
import { ReactComponent as MenuIcn } from "../../Assets/Images/icn/menu.svg";
import { ReactComponent as WPlus } from "../../Assets/Images/icn/plus.svg";
import { profile } from "store/services/profileService";
import { getAccountSettingDetail_ } from "store/services/accountSettingService";
import { useSelector } from "react-redux";

// css
import "./Header.scss";
import * as Path from "Routes/paths";
import * as actions from "store/actions";
// images
import logo from "../../Assets/Images/logo.png";
import SubscriptionInactivePopup from "components/Modals/SubscriptionInactivePopup";
import { billingStripeUrl_ } from "store/services/subscriptionService";
import { hasActivePlan, UC_FIRST } from "helpers/common";
import BannerMessage from "components/shared/BannerMessage";

const Header = ({
  dispatch,
  showSidebar,
  setSidebar,
  profileDetail,
  isPlanActive,
  plansStatus,
  freeAccount,
  pageTitle,
}) => {
  const [showSubscriptionPopup, setShowSubscriptionPopup] = useState(false);
  const todoAutoSaveInProgress = useSelector(state => state.Client.client.todoAutoSaveInProgress) 
  const bannerSettings = useSelector((state) => state.Misc.bannerSettings);
  const location = useLocation();
  const navigate = useNavigate();

  const handleSidebar = () => {
    setSidebar(!showSidebar);
  };

  useEffect(() => {
    if(!profileDetail) {
      return;
    }
    if (
      profileDetail.terms_accepted === "no"
    ) {
      navigate(Path.dashboardTermsCondition);
    } else if(!hasActivePlan(profileDetail)) {
      navigate(Path.dashboardSubscription);
    } else if(profileDetail && profileDetail?.is_free_account === 'no' && ["paused"].includes(plansStatus)) {
      setShowSubscriptionPopup(true);
    }
  }, [profileDetail]);

  useEffect(() => {
    getData();
    getDataSettingData();
  }, []);

  //get data
  async function getData() {
    try {
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(profile());
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      console.log(err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  //get data detail
  async function getDataSettingData() {
    try {
      await dispatch(getAccountSettingDetail_());
    } catch (err) {
      console.log(err);
    }
  }

  async function getBillingPortalUrl() {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let response = await dispatch(billingStripeUrl_());
      dispatch(actions.persist_store({ loader: false }));
      if(response?.success !== true || !response?.body?.url) {
        throw "URL not found in response";
      }
      return response?.body?.url;
    } catch (err) {
      console.log("Error generating billing portal url: ", err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  return (
    <>
      <SubscriptionInactivePopup
        showPopup={showSubscriptionPopup}
        handleClose={() => {
          setShowSubscriptionPopup(false);
        }}
        billingPortalUrl={getBillingPortalUrl}
      />
      <header
        className="siteHeader sticky-top border-bottom"
        style={{ background: "#FFF" }}
      >
        {bannerSettings && (
          <BannerMessage
            containerClass="nta-banner"
            data={bannerSettings}
          />
        )}
        <Container fluid>
          <Nav className="d-flex align-items-center justify-content-between w-100 gap-10 py-2">
            <div className="left d-flex align-items-center">
              {/* <Link to="/dashboard">
              <img src={logo} alt="" className="img-fluid" />
            </Link> */}
              <Button
                onClick={handleSidebar}
                className="border-0 p-0 d-lg-none me-2"
                variant="transparent"
              >
                <MenuIcn />
              </Button>
              <div className="d-flex align-items-center">
                <h1 className="pagetitle m-0 py-2 fw-bold ">
                  {UC_FIRST(pageTitle || location.pathname.split("/").pop().replace(/-/g, " "))}
                </h1>
                {todoAutoSaveInProgress && (
                  <p className="m-0 mx-2">(Saving...)</p>
                )}
              </div>
            </div>
            <Link
              to={
                profileDetail &&
                hasActivePlan(profileDetail) &&
                profileDetail.terms_accepted === "yes"
                  ? "/add-client"
                  : "#"
              }
              className="d-flex align-items-center btn text-white btnSm justify-content-center commonBtn px-3"
            >
              <span className="icn me-2">
                <WPlus />
              </span>{" "}
              Add New Client{" "}
            </Link>
          </Nav>
        </Container>
      </header>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    authentication: state.Auth.isAuthenticated,
    profileDetail: state.Profile.user,
    isPlanActive: state.Profile.user?.is_plan_active,
    plansStatus: state.Profile.user?.plan_status,
    freeAccount: state.Profile?.user?.is_free_account,
    pageTitle: state.Misc.currentPage?.title
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Header);

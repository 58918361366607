import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from 'yup';
import { formatPrice } from "helpers/common";

const validation = Yup.object({
  package_name: Yup.string().required("Package name is required"),
  package_desc: Yup.string().required("Package description is required"),
  package_price: Yup.number()
    .positive()
    .min(1, "Price must be greator than or equal to 1")
    .required("Package price is required"),
  session_count: Yup.number()
    .min(1, "Keep it between 1-100")
    .max(100, "Keep it between 1-100")
    .required("Session count is required"),
});

const AddPackagePop = ({
  addPackagePop,
  toggleAddPackagePop,
  currentPacakge,
  submitData_,
}) => {
  const editMode = !!currentPacakge;
  return (
    <>
      <Formik
        initialValues={{
          package_type : "session",
          package_name: (currentPacakge && currentPacakge.package_name) || "",
          package_desc: (currentPacakge && currentPacakge.package_desc) || "",
          package_price: (currentPacakge && currentPacakge.package_price) || "",
          session_count: (currentPacakge && currentPacakge.session_count) || 1,
        }}
        enableReinitialize
        validationSchema={validation}
        onSubmit={submitData_}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Modal
            show={addPackagePop}
            onHide={toggleAddPackagePop}
            backdrop="static"
            keyboard={false}
            centered
            size="lg"
            scrollable="true"
            className="FormPop"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                { editMode ?
                  "Update Package"
                  :
                  "New Package"
                }
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col lg="12" className="my-2">
                    <label htmlFor="" className="form-label m-0 fw-sbold">
                      Package Title <span className="text-muted">(required)</span>
                    </label>
                    <p className="m-0">
                      This is the title of your package.
                      It will be visible to clients when you select the 'send to client' option.
                    </p>
                    <input
                      type="text"
                      className={`form-control ${errors.package_name && "is-invalid"}`}
                      name="package_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.package_name}
                    />
                    {errors.package_name && touched.package_name && (
                      <p className="invalid-feedback">{errors.package_name}</p>
                    )}
                  </Col>
                  <Col lg="12" className="my-2">
                    <label htmlFor="" className="form-label m-0 fw-sbold">
                      Package Description <span className="text-muted">(required)</span>
                      {/* <span className="text-muted">(Optional)</span> */}
                    </label>
                    <p className="m-0">
                      Provide a short description of your package.
                    </p>
                    <textarea
                      name="package_desc"
                      id=""
                      rows="4"
                      className={`form-control ${errors.package_desc && "is-invalid"}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.package_desc}
                    ></textarea>
                    {errors.package_desc && touched.package_desc && (
                      <p className="invalid-feedback">{errors.package_desc}</p>
                    )}
                  </Col>
                  <Col lg="12" className="my-2">
                    <label htmlFor="" className="form-label m-0 fw-sbold">
                      Package Price <span className="text-muted">(required)</span>
                    </label>
                    <p className="m-0">
                      The price of the package. Clients will be charged this amount
                      when they sign up for this package.
                    </p>
                    <input
                      type="number"
                      className={`form-control ${errors.package_price && "is-invalid"}`}
                      name="package_price"
                      placeholder=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.package_price}
                    />
                    { typeof values.package_price == "number" &&
                      <p className="m-0">
                        A payment of{" "}
                        <span className="fw-sbold">${formatPrice(values.package_price)} (USD)</span> will be
                        required for clients to sign up for this package
                      </p>
                    }
                    {errors.package_price && touched.package_price && (
                      <p className="invalid-feedback">{errors.package_price}</p>
                    )}
                  </Col>
                  {/* <Col lg="12" className="my-2">
                    <label htmlFor="" className="form-label m-0 fw-sbold">
                      Package Invite email{" "}
                      <span className="text-muted">(Optional)</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      onChange={handleChange("email")}
                      value={fields.email}
                    />
                  </Col> */}
                  <Col lg="12" className="my-2">
                    <label htmlFor="" className="form-label m-0 fw-sbold">
                      Total Sessions <span className="text-muted">(required)</span>
                    </label>
                    <p className="m-0">
                      Total number of sessions provided in this package
                    </p>
                    <input
                      type="number"
                      className={`form-control ${errors.session_count && "is-invalid"}`}
                      name="session_count"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.session_count}
                    />
                    {errors.session_count && touched.session_count && (
                      <p className="invalid-feedback">{errors.session_count}</p>
                    )}
                  </Col>
                  <Col>
                    <div className="d-flex gap-10 FormbtnWrpper">
                      <Button
                        className="d-flex align-items-center justify-content-center commonBtn btnSm"
                        onClick={toggleAddPackagePop}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="d-flex align-items-center justify-content-center commonBtn btnSm"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Please Wait..." : "Submit"}
                      </Button>

                    </div>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default AddPackagePop;

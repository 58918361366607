import { APPOINTMENT_DATE_FORMAT } from "common/constants";
import LoadingIndicator from "components/shared/LoadingIndicator";
import LoadingSkeleton from "components/shared/LoadingSkeleton/Index";
import { FULL_NAME, isEmpty } from "helpers/common";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, ListGroup, Modal, Row } from "react-bootstrap";
import { generatePath, Link } from "react-router-dom";
import { getUtilizationHistoryByClient } from "store/services/packageService";
import * as actions from "store/actions";
import { myClientDetails } from "Routes/paths";

const ClientPackageHistoryPopup = ({ dispatch, data, showPopup, setShowPopup }) => {
  const [isLoading, setLoading] = useState(false);
  const [appointmentList, setAppointmentList] = useState([]);
  const [clientInfo, setClientInfo] = useState(null);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const limit = 10;

  console.log({ page, hasMore })

  useEffect(() => {
    if(showPopup) {
      resetAndFetchAppointments();
    }
  }, [data?.package_invite_id, showPopup]);

  const resetAndFetchAppointments = () => {
    setAppointmentList([]);
    setClientInfo(null);
    setPage(1);
    setHasMore(true);
    getClientPackageActivity(1);
  };

  async function getClientPackageActivity(pageToFetch) {
    try {
      if(!data?.package_invite_id) {
        return;
      }
      setLoading(true);
      const payload = {
        invitation_id: data?.package_invite_id,
        limit: limit,
        skip: pageToFetch,
      }
      dispatch(actions.persist_store({ loader: true }));
      const response = await getUtilizationHistoryByClient(payload);
      dispatch(actions.persist_store({ loader: false }));
      if(!response.success) {
        return;
      }
      const newAppointments = response?.body?.data || [];
      setAppointmentList((prev) => [...prev, ...newAppointments]);

      // Calculate hasMore using total_count and per_page
      const currentPage = pageToFetch;
      const totalPages = response?.body?.total_pages;
      setHasMore(currentPage < totalPages); // hasMore is true if there are more pages

      setClientInfo(response?.body?.invitationDetails);
      setLoading(false);
    } catch(err) {
      dispatch(actions.persist_store({ loader: false }));
      setLoading(false);
      console.error("Error getting client package activity", err);
    }
  }

  const loadMoreAppointments = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    getClientPackageActivity(nextPage);
  };

  const handleClose = () => {
    setShowPopup(false);
  }

  if(!data) {
    return "";
  }

  return (
    <>
      <Modal
        show={showPopup}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {FULL_NAME(data?.first_name, data?.last_name)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {clientInfo && (
              <Col lg="12" className="">
                <ul className="list-unstyled ps-0 mb-0">
                  <li className="d-flex align-items-start gap-10 py-1">
                    <div className="left d-flex align-items-center w-25">
                      <p className="m-0 text-dark fw-bold">Client Name</p>
                    </div>
                    <div className="right w-75">
                      <p className="m-0">
                        <Link
                          target="_blank"
                          to={generatePath(myClientDetails, { clientId: clientInfo?.client_id })}
                        >
                          {FULL_NAME(clientInfo?.first_name, clientInfo?.last_name)}
                        </Link>
                      </p>
                    </div>
                  </li>
                  <li className="d-flex align-items-start gap-10 py-1">
                    <div className="left d-flex align-items-center w-25">
                      <p className="m-0 text-dark fw-bold">Phone</p>
                    </div>
                    <div className="right w-75">
                      <p className="m-0">
                        {!isEmpty(clientInfo?.phone) ? clientInfo?.phone : "--"}
                      </p>
                    </div>
                  </li>
                  <li className="d-flex align-items-start gap-10 py-1">
                    <div className="left d-flex align-items-center w-25">
                      <p className="m-0 text-dark fw-bold">Email</p>
                    </div>
                    <div className="right w-75">
                      <p className="m-0">
                        {clientInfo?.email}
                      </p>
                    </div>
                  </li>
                  <li className="d-flex align-items-start gap-10 py-1">
                    <div className="left d-flex align-items-center w-25">
                      <p className="m-0 text-dark fw-bold">Sessions Remaining</p>
                    </div>
                    <div className="right w-75">
                      <p className="m-0">
                        {clientInfo?.appointments_remaining}
                      </p>
                    </div>
                  </li>
                </ul>
              </Col>
            )}
            <Col lg="12" className="py-2">
              {appointmentList?.length > 0 ? (
                <div className="upcoming-appointments py-2">
                  <p className="mb-2">
                    Below are all the appointments {FULL_NAME(clientInfo?.first_name, clientInfo?.last_name)} has booked through the current package.
                  </p>
                  <ListGroup>
                    {appointmentList.map((item, index) => (
                      <ListGroup.Item key={index}>
                        <Link
                          target="_blank"
                          className="underline"
                          to={`/appointment?apptId=${item.appointment_id}`}
                        >
                          {item.title}
                        </Link>
                        <p className="m-0">
                          <span className="date">
                            {item.start_time_tz &&
                              moment(item.start_time_tz).format(APPOINTMENT_DATE_FORMAT)}{" "}
                            to{" "}
                            {item.end_time_tz &&
                              moment(item.end_time_tz).format(APPOINTMENT_DATE_FORMAT)}
                          </span>
                        </p>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                  {hasMore && (
                    <div className="text-center mt-3">
                      <Button
                        onClick={loadMoreAppointments}
                        className="d-inline-flex btn text-white align-items-center justify-content-center commonBtn btnSm"
                      >
                        {isLoading ? "Loading..." : "Load More"}
                      </Button>
                    </div>
                  )}
                </div>
              ) : (
                <p>No appointments found.</p>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="FormbtnWrpper justify-content-end d-flex gap-10">
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClientPackageHistoryPopup;

//action types
export const GET_PACKAGE = 'GET_PACKAGE';
export const ADD_PACKAGE = 'ADD_PACKAGE';
export const VIEW_PACKAGE = 'VIEW_PACKAGE';
export const UPDATE_PACKAGE = 'UPDATE_PACKAGE';
export const DELETE_PACKAGE = 'DELETE_PACKAGE';
export const PACKAGE_INIVITATIONS = "PACKAGE_INIVITATIONS";
export const GET_PACKAGE_UTILIZATION = "GET_PACKAGE_UTILIZATION";

//get 
export function getPackage(data, callback) {
  return {
    type: GET_PACKAGE,
    payload : {data, callback},
  };
}
//add 
export function addPackage(data, callback) {
  return {
    type: ADD_PACKAGE,
    payload : {data, callback},
  };
}

//view 
export function viewPackage(data, callback) {
  return {
    type: VIEW_PACKAGE,
    payload : {data, callback},
  };
}
//update 
export function updatePackage(data, callback) {
    return {
      type: UPDATE_PACKAGE,
      payload : {data, callback},
    };
}
  
//delete 
export function deletePackage(payload) {
  return {
    type: DELETE_PACKAGE,
    payload
  };
}


// package invitations
export function getPackageInvitations(data, callback) {
  return {
    type: PACKAGE_INIVITATIONS,
    payload: {data, callback}
  };
}

// package utilization history
export function getPackageUtilizationHistory(data, callback) {
  return {
    type: GET_PACKAGE_UTILIZATION,
    payload: {data, callback}
  };
}
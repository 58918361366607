import {postData, getData, putData, deleteData, getDataAuth} from 'helpers/apiHelper'
import { MAIN_PATH, ACCOUNT_SETTING_LIST, DEFAULT_FORM_LIST, ADD_UPDATE_ACCOUNT_SETTING, GET_ACCOUNT_SETTING_DETAIL, GET_ACCOUNT_SETTING_DETAIL_ , SELF_REGISTER_LIST, ADD_SELF_REGISTER, GET_THIRD_PARTY_INTEGRATION_STATUS, CONNECT_STRIPE_ACCOUNT, DISCONNECT_STRIPE_ACCOUNT, CONNECT_GOOGLE_CALENDAR, DISCONNECT_GOOGLE_CALENDAR, CONNECT_FULLSCRIPT_ACCOUNT, DISCONNECT_FULLSCRIPT_ACCOUNT, GENERATE_PRACTICE_BETTER_KEY, DISCONNECT_PRACTICE_BETTER_ACCOUNT, STRIPE_LOGIN_LINK, MAIN_PATH_CLIENTS} from './apiPath'
import { getAccountSettingList, getDefaultFormList, addUpdateAccountSetting, getSettingDetail, getAccountSettingDetail, getSelfRegisteration, addSelfRegisteration, getThirdPartyIntegrationStatus, connectStripe } from 'store/actions/accountSetting';


//get
export function getAccountSettingList_(value, callback) {
    let url = `${MAIN_PATH}${ACCOUNT_SETTING_LIST}`;
    return (dispatch) => getData(url, value)
      .then((data) => {
        dispatch(getAccountSettingList(data, callback));
        dispatch(getAccountSettingDetail_(data, callback));
        return data;
      });
}

//default form list
export function getDefaultFormList_(value, callback) {
    let url = `${MAIN_PATH}${DEFAULT_FORM_LIST}`;
    return (dispatch) => getData(url, value)
      .then((data) => {
        dispatch(getDefaultFormList(data, callback));
        return data;
    });
}

//add update account setting
export function addUpdateAccountSetting_(id, value, callback) {
    return (dispatch) => putData(`${MAIN_PATH}${ADD_UPDATE_ACCOUNT_SETTING}${id}`, value)
      .then((data) => {
        dispatch(addUpdateAccountSetting(data, callback));
        return data;
    });
}

//get detail setting
export function getSettingDetail_(value, callback) {
  return (dispatch) => getData(`${MAIN_PATH}${GET_ACCOUNT_SETTING_DETAIL}${value}`)
    .then((data) => {
      dispatch(getSettingDetail(data, callback));
      return data;
  });
}

//get account detail setting
export function getAccountSettingDetail_(value, callback) {
  return (dispatch) => getDataAuth(`${MAIN_PATH}${GET_ACCOUNT_SETTING_DETAIL_}`, value)
    .then((data) => {
      dispatch(getAccountSettingDetail(data, callback));
      return data;
  });
}
//self registeration
export function getSelfRegisteration_(value, callback) {
  return (dispatch) => getData(`${MAIN_PATH_CLIENTS}${SELF_REGISTER_LIST}`, value)
    .then((data) => {
      dispatch(getSelfRegisteration(data, callback));
      return data;
  });
}

//add self registeration
export function addSelfRegisteration_(id, value, callback) {
  return (dispatch) => postData(`${MAIN_PATH_CLIENTS}${ADD_SELF_REGISTER}${id}`, value)
    .then((data) => {
      dispatch(addSelfRegisteration(data, callback));
      return data;
  });
}

//self registeration
export function getIntegrationStatus(callback) {
  return (dispatch) => getData(`${MAIN_PATH}${GET_THIRD_PARTY_INTEGRATION_STATUS}`)
    .then((data) => {
      dispatch(getThirdPartyIntegrationStatus(data, callback));
      return data;
  });
}

// connect Stripe account
export function connectStripeAccount(payload, callback) {
  let url = `${MAIN_PATH}${CONNECT_STRIPE_ACCOUNT}`;
  return postData(url, payload)
}

// disconnect Stripe account
export function disconnectStripeAccount(callback) {
  return (dispatch) => deleteData(`${MAIN_PATH}${DISCONNECT_STRIPE_ACCOUNT}`)
    .then(async (data) => {
      return await dispatch(getIntegrationStatus(callback));
  });
}

// get stripe express dashboard link
export function getStripeLoginLink(callback) {
  let url = `${MAIN_PATH}${STRIPE_LOGIN_LINK}`;
  // let url = `http://localhost:3000/dev/stripe/login-link`;
  return postData(url);
}

// connect Google calendar
export function connectGoogleCalendar(callback) {
  return getData(`${MAIN_PATH}${CONNECT_GOOGLE_CALENDAR}`)
}

// disconnect Google calendar
export function disconnectGoogleCalendar(callback) {
  return (dispatch) => postData(`${MAIN_PATH}${DISCONNECT_GOOGLE_CALENDAR}`)
    .then(async (data) => {
      return await dispatch(getIntegrationStatus(callback));
  });
}

// connect fullscript 
export function connectFullscriptAccount(data) {
  return postData(`${MAIN_PATH}${CONNECT_FULLSCRIPT_ACCOUNT}`, data)
}

// disconnect Fullscript account
export function disconnectFullscriptAccount(callback) {
  return (dispatch) => postData(`${MAIN_PATH}${DISCONNECT_FULLSCRIPT_ACCOUNT}`)
    .then(async (data) => {
      return dispatch(getIntegrationStatus(callback));
  });
}

// generate practice better key
export function generatePracticeBetterKey() {
  return postData(`${MAIN_PATH}${GENERATE_PRACTICE_BETTER_KEY}`)
}

// disconnect practice better account
export function disconnectPracticeBetterAccount(callback) {
  let url = `${MAIN_PATH}${DISCONNECT_PRACTICE_BETTER_ACCOUNT}`;
  // let url = `http://localhost:3000/dev/pb/unlink-account-nutriq`;
  return (dispatch) => postData(url)
    .then(async (data) => {
      return dispatch(getIntegrationStatus(callback));
  });
}
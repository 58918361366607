import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import PackageInvitationsTab from "./Components/Tabs/InvitationsTab";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { viewPackage_ } from "store/services/packageService";
import PackagePurchaseHistoryTab from "./Components/Tabs/PurchaseHistoryTab";
import PackageUsageOverviewTab from "./Components/Tabs/UsageOverviewTab";
import PackageInfoTab from "./Components/Tabs/PackageInfoTab";
import * as actions from "store/actions";

import "./PackageDetail.scss";
import { formatPrice, getPackageSessionPrice } from "helpers/common";
import { connect } from "react-redux";

const PackageDetail = ({ dispatch }) => {
  const { id } = useParams();
  const [details, setDetails] = useState(null);


  useEffect(() => {
    getPackageDetails();
  }, []);

  async function getPackageDetails() {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let response = await viewPackage_(id);
      if(response?.success && response?.body?.id) {
        setDetails(response.body);
      }
      dispatch(actions.persist_store({ loader: false }));
    } catch(err) {
      dispatch(actions.persist_store({ loader: false }));
      console.error("Error fetching package details", err);
    }
  }

  return (
    <section
      className="packageDetails position-relative"
      style={{ overflow: "hidden" }}
    >
      <Container fluid>
        <Row>
          <Col lg="12" className="my-2">
            <Row>
              <Col lg="3" className="my-2">
                <div className="content">
                  <h6 className="m-0 mb-1 fw-bold">
                    {details?.package_name}
                  </h6>
                  <p className="m-0 fw-sbold ws-nowrap theme-clr">
                    Price ${formatPrice(details?.package_price)} (USD)
                  </p>
                  <p className="m-0 fw-sbold ws-nowrap theme-clr">
                    ${getPackageSessionPrice(details?.package_price, details?.session_count)} (USD)/session
                  </p>
                </div>
              </Col>
              <Col lg="9" className="my-2">
                <div
                  className="position-relative p-3 p-lg-4 gettingStarted"
                  style={{
                    background: "#fff8f6",
                    zIndex: "9",
                    borderColor: "#f4694b",
                  }}
                >
                  <div className="top d-flex align-items-start justify-content-between gap-10 commonContent">
                    <div className="left">
                      <h3 className="m-0 head py-2 fw-bold">
                        Description
                      </h3>
                    </div>
                  </div>
                  <div className="CardBody py-2">
                    <p className="m-0 py-2 is-multiline">
                      {details?.package_desc}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg="12" className="my-2">
            <div className="commonCardBox">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="Invitations"
                // activeKey={activeTab}
                // onSelect={(tabName) => {
                //   updateActiveTab(tabName);
                // }}
              >
                <div className="top border-bottom px-lg-4 py-3">
                  <Nav variant="pills" className="commonBtnTabs">
                    <Nav.Item className="px-2">
                      <Nav.Link
                        className="fw-sbold text-uppercase py-2"
                        eventKey="Invitations"
                      >
                        Invitations
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="px-2">
                      <Nav.Link
                        className="fw-sbold text-uppercase py-2"
                        eventKey="PurchaseHistory"
                      >
                        Purchased
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="px-2">
                      <Nav.Link
                        className="fw-sbold text-uppercase py-2"
                        eventKey="UsageOverview"
                      >
                        Package Activity
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="px-2">
                      <Nav.Link
                        className="fw-sbold text-uppercase py-2"
                        eventKey="PackageInfo"
                      >
                        Package Info
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="CardBody p-3 px-lg-4">
                  <Tab.Content>
                    <Tab.Pane eventKey="Invitations" mountOnEnter={true}>
                      <PackageInvitationsTab packageDetails={details} />
                    </Tab.Pane>
                  </Tab.Content>
                  <Tab.Content>
                    <Tab.Pane eventKey="PurchaseHistory" mountOnEnter={true}>
                      <PackagePurchaseHistoryTab />
                    </Tab.Pane>
                  </Tab.Content>
                  <Tab.Content>
                    <Tab.Pane eventKey="UsageOverview" mountOnEnter={true}>
                      <PackageUsageOverviewTab />
                    </Tab.Pane>
                  </Tab.Content>
                  <Tab.Content>
                    <Tab.Pane eventKey="PackageInfo" mountOnEnter={true}>
                      <PackageInfoTab
                        packageDetails={details}
                        getPackageDetails={getPackageDetails}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

const mapStateToPros = (state) => {
  return {
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(PackageDetail);
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
// icn
import { ReactComponent as AddIcn } from "../../../../../Assets/Images/icn/AddCircle.svg";
import { ReactComponent as StarIcn } from "../../../../../Assets/Images/icn/circleStar.svg";
import { ReactComponent as DelIcn } from "Assets/Images/icn/delete.svg";
import { Link } from "react-router-dom";
import { DATE_FORMAT, FORMAT_TEXT, FULL_NAME } from "helpers/common";
import StarRatings from "react-star-ratings";
import { addUpdateFavorite_ } from "store/services/clientService";
import ReactPagination from "components/shared/ReactPagination";
import * as actions from 'store/actions';

let columns = [
  {
    key: "first_name",
    sortable: true,
    name: "Name",
  },
  {
    key: "email",
    sortable: true,
    name: "Email",
  },
  {
    key: "is_archived",
    sortable: true,
    name: "Group",
  },
  {
    key: "status",
    sortable: true,
    name: "Type",
  },
  {
    key: "created_at",
    sortable: true,
    name: "Added",
  },
  {
    key: "is_favorite",
    sortable: true,
    name: "Fav",
  },
  {
    key: "tags_assigned",
    sortable: true,
    name: "Tags",
  },
];

const TableData = ({
  dispatch,
  clientListing,
  handleAddTagAssign,
  setUpdateFavStatus,
  activePage,
  itemsCountPerPage,
  totalItemsCount,
  handlePageChange,
  tagList,
  selectedCheckboxes,
  ClientListRes,
  handleRemoveTag,
  sortBy,
  setSortBy,
  sortOrder,
  setSortOrder,
  getClientList,
  setPageSize
}) => {
  const onchangeRating = async (data) => {
    try{
        let obj = {
          mark_favorite : data.is_favorite === "no" ? "yes" : data.is_favorite === "yes" ? "no" : "yes"
        }
        obj = JSON.stringify(obj)
        let clientId = data?.id
        dispatch(actions.persist_store({ loader: true }));
        await dispatch(addUpdateFavorite_(clientId, obj));
        await getClientList();
        dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err, "==============================");
    }
  };

  const SortableColumns = () => {
    return columns.map(column => {
      let sortClassName = "fa-sort";
      let sortClass = "";
      if(sortBy == column.key) {
        sortClass = (sortOrder == "asc") ? "fa-sort-up" : "fa-sort-down";
      }
      return (
        <th className={`p-3 fw-normal text-dark ${column.sortable ? "sortable" : ""}`}>
          {column.sortable ?
            <Link
              onClick={() => {
                let order = sortOrder === "asc" ? "desc" : "asc";
                setSortBy(column.key);
                setSortOrder(order);
              }}
            >
              {column.name}
              <i class={`fa ${sortClassName} ${sortClass} px-2`}></i>
            </Link>
            :
            <>
              {column.name}
            </>
          }
          {column.desc && <sub>({column.desc})</sub> }
        </th>
      )
    })
  }

  return (
    <>
      <div className="table-responsive">
        <table className="table m-0">
          <thead>
            <tr>
              <SortableColumns />
            </tr>
          </thead>
          <tbody>
            {(clientListing && clientListing.length > 0) ?
              clientListing.map((data, idx) => {
                let array_tags = [];
                try {
                  array_tags = data?.tags_assigned ? JSON.parse(data?.tags_assigned) : [];
                } catch(err) {
                  console.log("My Clients: Error parsing tags", err);
                }
                let arr = selectedCheckboxes;
                // if (arr.length> 0 && arr.some(val => array_tags.includes(val))) {
                //   let status;
                //   if (data.is_direct_account === 'yes') {
                //     status = 'Direct Link';
                //   } else if (data.status === 'active' &&  data.is_direct_account === 'no') {
                //       status = 'Active';
                //   } else {
                //     status = 'Unlinked';
                //   }
                //   return (
                //     <>
                //       <tr key={idx}>
                //         <td className="p-3 border-0">
                //           <Link to={`/my-clients/${data.id}/detail`} className="text-dark">
                //             <b className="text-dark">
                //               {data.first_name ? FORMAT_TEXT(FULL_NAME(data.first_name, data.last_name)) : "N/A"}
                //             </b>
                //           </Link>
                //         </td>
                //         <td className="p-3 border-0">
                //           {data.email ? FORMAT_TEXT(data.email) : "N/A"}
                //         </td>
                //         <td className="p-3 border-0">{data?.is_archived === 'yes' ? 'Archived' : 'Current'}</td>
                //         <td className="p-3 border-0">
                //         {" "}
                //           {status}
                //         </td>
                //         <td className="p-3 border-0">
                //           {DATE_FORMAT(data.created_at)}
                //         </td>
                //         <td className="p-3 border-0">
                //         <span className="icn">
                //           <StarRatings
                //             rating={data.is_favorite === 'yes' ? 1 : 0}
                //             starRatedColor="#e79d46"
                //             changeRating={() => onchangeRating(data)}
                //             starDimension="20px"
                //             starSpacing="15px"
                //             numberOfStars={1}
                //             name="is_favorite"
                //           />
                //         </span>
                //         </td>
                //         <td className="p-3 border-0 ws-normal">
                //           <div className="TableBtn d-flex align-items-center gap-10 flex-wrap">
                //             <ul className="list-unstyled ps-0 mb-0 d-flex align-items-center gap-10 flex-wrap">
                //               {tagList.length>0 && tagList.map((tag, i) => {
                //                 let array = JSON.parse(data?.tags_assigned);
                //                 if (array.length > 0 && array.includes(tag?.id)) {
                //                     return (
                //                         <li className="" key={i}>
                //                             <span
                //                                 className="CstmLabel"
                //                                 style={{
                //                                     borderColor: tag.color_code,
                //                                     color: "#fff",
                //                                     background: tag.color_code,
                //                                 }}
                //                             >
                //                                 {tag.name} ({i})
                //                             </span>
                //                         </li>
                //                     );
                //                 }
                //               })}
                //             </ul>
                //             <Button
                //               variant="transparent"
                //               onClick={() => handleAddTagAssign(data)}
                //               className="d-flex align-items-center justify-content-center rounded-circle border-0 p-0"
                //             >
                //               <AddIcn />
                //             </Button>
                //           </div>
                //         </td>
                //       </tr>
                //     </>
                //   );
                // }
                let status;
                if (data.is_direct_account === 'yes') {
                  status = 'Direct Link';
                } else if (data.status === 'active' &&  data.is_direct_account === 'no') {
                    status = 'Active';
                } else {
                  status = 'Unlinked';
                }
                return (
                  <>
                    <tr key={idx}>
                      <td className="p-3 border-0">
                        <Link to={`/my-clients/${data.id}/detail`} className="text-dark">
                          <b className="text-dark">
                            {data.first_name ? FORMAT_TEXT(FULL_NAME(data.first_name, data.last_name)) : "N/A"}
                          </b>
                        </Link>
                      </td>
                      <td className="p-3 border-0">
                        {data.email ? FORMAT_TEXT(data.email) : "N/A"}
                      </td>
                      <td className="p-3 border-0">{data?.is_archived === 'yes' ? 'Archived' : 'Current'}</td>
                      <td className="p-3 border-0">
                      {" "}
                        {status}
                      </td>
                      <td className="p-3 border-0">
                        {DATE_FORMAT(data.created_at)}
                      </td>
                      <td className="p-3 border-0">
                      <span className="icn">
                        <StarRatings
                          rating={data.is_favorite === 'yes' ? 1 : 0}
                          starRatedColor="#e79d46"
                          changeRating={() => onchangeRating(data)}
                          starDimension="20px"
                          starSpacing="15px"
                          numberOfStars={1}
                          name="is_favorite"
                        />
                      </span>
                      </td>
                      <td width={30} className="p-3 border-0 ws-normal dsss">
                        <div className="TableBtn d-flex align-items-center gap-10 flex-wrap">
                          <ul className="list-unstyled ps-0 mb-0 d-flex align-items-center gap-10 flex-wrap">
                            {tagList.length>0 && tagList.map((tag, i) => {
                              let array = [];
                              try {
                                array = data?.tags_assigned ? JSON.parse(data?.tags_assigned) : [];
                              } catch(err) {
                                console.log("My Clients: Error parsing tags", err);
                              }
                              if (array.length > 0 && array.includes(tag?.id)) {
                                  return (
                                    <li className="single-tag" key={i}>
                                        <span
                                            className="CstmLabel"
                                            style={{
                                                borderColor: tag.color_code,
                                                color: "#fff",
                                                background: tag.color_code,
                                            }}
                                        >
                                            {tag.name}
                                            <Link className="btn p-1" onClick={() => handleRemoveTag(data.id, tag)}>
                                              <DelIcn className="delete-icon" />
                                            </Link>
                                        </span>
                                    </li>
                                  );
                              }
                            })}
                          </ul>
                          <Button
                            variant="transparent"
                            onClick={() => handleAddTagAssign(data)}
                            className="d-flex align-items-center justify-content-center rounded-circle border-0 p-0"
                          >
                            <AddIcn />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })
              :
              <tr>
                <td colSpan={7} className="text-center border-0">No data found</td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      {clientListing.length>0 &&
          <ReactPagination 
              activePage={activePage}
              itemsCountPerPage={itemsCountPerPage}
              totalItemsCount={totalItemsCount}
              handlePageChange={handlePageChange}
              showPageSizeDropdown={true}
              pageSizeOptions={[10, 20, 50]}
              onPageSizeChange={setPageSize}
          />
      }
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    tagList : state.Tags.tags,
    // activePage : state.Client.activePage,
    itemsCountPerPage : state.Client.itemsCountPerPage,
    totalItemsCount : state.Client.totalItemsCount,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(TableData);

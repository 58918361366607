import { FULL_NAME } from "helpers/common";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const SendInviteSuccessPopup = ({ showPopup, setShowPopup, client }) => {

  const closePopup = () => {
    setShowPopup(false);
  }

  return (
    <>
      <Modal
        show={showPopup}
        onHide={closePopup}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        scrollable="true"
        className="FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title>Success!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg="12" className="my-2">
              <p className="m-0 fw-sbold pb-1">
              Package invite was successfully sent! We'll notify you when{` `}
              {FULL_NAME(client?.first_name, client?.last_name)} completes purchasing the package.
              </p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="gap-10 FormbtnWrpper">
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={closePopup}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SendInviteSuccessPopup;
